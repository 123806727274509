<!--
 * 时长详情
-->
<template>
  <div class="container time-record-page">
    <NavBar class="navbar" />
    <div class="picker-container">
      <div class="date-picker-container" @click="dataPickerShow = true">
        <span>{{ currentDateString }}</span>
        <img
          class="arrow-down-icon"
          :src="require('@/assets/icon/arrow-down-icon.png')"
        />
      </div>
      <div class="type-picker-container" @click="typePickerShow = true">
        <span>{{ orderType | orderTypeFilter }}</span>
        <img
          class="arrow-down-icon"
          :src="require('@/assets/icon/arrow-down-icon.png')"
        />
      </div>
    </div>
    <div class="time-record-list-container" ref="warp">
      <Empty v-if="!list.length && reqFlag"></Empty>
      <van-list
        v-else
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getTimeRecordList"
      >
        <div
          class="time-record-item-container"
          v-for="item in list"
          :key="item.timeUserTransactionId"
          :title="item"
        >
          <div class="top">
            <div class="time-record-name">
              {{ item.orderType | orderTypeFilter }}
            </div>
            <div class="time">{{ item.createTime }}</div>
          </div>
          <div class="bottom">
            <div class="time-record-action">{{ item.brief }}</div>
            <div class="duration">
              <span>{{ item.transactionType === 0 ? '+' : '-' }}</span>
              {{ item.transactionSeconds | secondFilter }}
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <van-popup v-model="dataPickerShow" round position="bottom">
      <van-datetime-picker
        :value="createTime"
        type="year-month"
        title="选择年月"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onSelectDate"
        @cancel="dataPickerShow = false"
      />
    </van-popup>
    <van-popup
      v-model="typePickerShow"
      round
      position="bottom"
      closeable
      :style="{ height: '326px' }"
    >
      <div class="type-list-container">
        <van-row>
          <van-col span="8"
            ><div
              class="type-item"
              @click="onSelectOrderType('')"
              :class="orderType === '' ? 'active' : ''"
            >
              全部类型
            </div></van-col
          >
          <van-col span="8"
            ><div
              class="type-item"
              @click="onSelectOrderType(3)"
              :class="orderType === 3 ? 'active' : ''"
            >
              使用
            </div></van-col
          >
          <van-col span="8"
            ><div
              class="type-item"
              @click="onSelectOrderType(1)"
              :class="orderType === 1 ? 'active' : ''"
            >
              购买
            </div></van-col
          >
          <van-col span="8"
            ><div
              class="type-item"
              @click="onSelectOrderType(2)"
              :class="orderType === 2 ? 'active' : ''"
            >
              兑换
            </div></van-col
          >
          <van-col span="8"
            ><div
              class="type-item"
              @click="onSelectOrderType(5)"
              :class="orderType === 5 ? 'active' : ''"
            >
              系统赠送
            </div></van-col
          >
          <van-col span="8"
            ><div
              class="type-item"
              @click="onSelectOrderType(4)"
              :class="orderType === 4 ? 'active' : ''"
            >
              过期
            </div></van-col
          >
        </van-row>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getTimeRecord } from '@/api/goods'
import convert from 'convert-seconds-to-human'
import NavBar from '@/components/NavBar.vue'
import Empty from '@/components/Empty.vue'
export default {
  name: 'time-record',
  components: {
    Empty,
    NavBar
  },
  data() {
    return {
      reqFlag: false,
      list: [],
      loading: false,
      finished: false,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(),
      createTime: new Date(),
      orderType: '', // 1 购买；2兑换；3：使用
      dataPickerShow: false,
      typePickerShow: false,
      pageNum: 1,
      pageSize: 10
    }
  },
  computed: {
    currentDateString() {
      return this.$dayjs(this.createTime).format('YYYY年MM月')
    }
  },
  filters: {
    orderTypeFilter(val) {
      const map = {
        1: '购买',
        2: '兑换',
        3: '使用',
        4: '过期',
        5: '系统赠送'
      }
      return map[val] || '全部类型'
    },
    secondFilter(val) {
      const resultCal = convert(val, 'cal')
      let timeStr = ''
      let hours =
        (resultCal.years || 0) * 24 * 365 +
        (resultCal.days || 0) * 24 +
        (resultCal.hours || 0)
      if (hours > 0) {
        timeStr += hours + '小时'
      }
      if (resultCal.minutes) {
        timeStr += resultCal.minutes + '分钟'
      }
      return timeStr
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    onSelectDate(val) {
      this.createTime = val
      this.getTimeRecordList(1)
      this.dataPickerShow = false
      // 请求数据
    },
    onSelectOrderType(orderType) {
      this.orderType = orderType || ''
      this.getTimeRecordList(1)
      this.typePickerShow = false
    },
    getTimeRecordList(num) {
      this.reqFlag = true
      this.$toast.loading({
        message: '加载中',
        forbidClick: true
      })
      this.loading = true
      if (num) {
        this.pageNum = num
      }
      if (this.pageNum === 1) {
        this.$refs.warp.scrollTo(0, 0)
        this.finished = false
      }
      const createTime = this.$dayjs(this.createTime).format('YYYY-MM')
      console.log(createTime)
      const params = {
        createTime,
        orderType: this.orderType,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      getTimeRecord(params)
        .then((res) => {
          if (res.status === 200) {
            if (this.pageNum === 1) {
              this.list = []
            }
            this.list = [...this.list, ...res.data.list]
            if (this.list.length >= res.data.totalItems) {
              this.finished = true
            }
            this.pageNum++
          }
          this.$toast.clear()
        })
        .finally(() => {
          this.loading = false
        })
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        // for (let i = 0; i < 10; i++) {
        //   this.list.push(this.list.length + 1)
        // }

        // 加载状态结束
        this.loading = false

        // 数据全部加载完成
        this.finished = true
        this.reqFlag = true
        // if (this.list.length >= 40) {
        //   this.finished = true
        //   }
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.time-record-page {
  .picker-container {
    display: flex;
    .date-picker-container,
    .type-picker-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      width: 187px;
      color: @text-color-1;
      .arrow-down-icon {
        margin-left: 2px;
        width: 14px;
        height: 14px;
      }
    }
  }
  .time-record-list-container {
    padding: 4px 16px;
    height: calc(100vh - 44px - 68px);
    margin: 0 16px;
    overflow: auto;
    background: @bg-color-2;
    border-radius: 4px;
    &::-webkit-scrollbar {
      display: none;
    }
    .time-record-item-container {
      &:not(:last-child) {
        border-bottom: 1px solid #333;
      }
      padding: 16px 0;
      .top,
      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: @text-color-1;
      }
      .top {
        margin-bottom: 12px;

        .time-record-name {
          font-size: 14px;
        }
        .time {
          font-size: 13px;
          color: @text-color-2;
        }
      }
      .bottom {
        .time-record-action {
          font-size: 13px;
        }
        .duration {
          font-weight: bold;
          font-size: 14px;
        }
      }
    }
  }
  .type-list-container {
    padding: 49px 16px;
    .type-item {
      margin: 0 auto;
      margin-bottom: 21px;
      width: 98px;
      height: 42px;
      background: @bg-color-3;
      border-radius: 2px;
      line-height: 42px;
      text-align: center;
      color: @text-color-2;
      &.active {
        background: @orange;
        color: @text-color-1;
      }
    }
  }
}
</style>
